











































import { mdiEmail, mdiEye, mdiEyeOff, mdiLock } from "@mdi/js"

import { email, required } from "@/composables/formRules"
import { useStatus } from "@/composables/status"
import { useSettings } from "@/store/settings"
import { useUser } from "@/store/user"

import Alert from "./Alert.vue"
import LoginAdvancedSettings from "./LoginAdvancedSettings.vue"

interface IVForm {
  validate: () => boolean
  reset: () => void
}

export default defineComponent({
  components: { Alert, LoginAdvancedSettings },
  setup(_, { root }) {
    const valid = ref(false)
    const showPass = ref(false)
    const formRef = ref<IVForm | null>(null)
    const { statusAlert, resetStatus, setStatus } = useStatus()
    const user = useUser()
    const settings = useSettings()
    const form = reactive({
      password: "",
      username: "",
    })
    const icons = { mdiEmail, mdiEye, mdiEyeOff, mdiLock }

    async function onSubmit() {
      resetStatus()
      formRef.value?.validate()
      if (!valid.value) return
      const response = await user.login(form.username, form.password)
      if (response.data !== null) {
        formRef.value?.reset()
        await settings.updateSettings()
        await root.$router.push("/pipelines")
      } else {
        setStatus("error", response.error)
      }
    }
    return {
      email,
      form,
      formRef,
      icons,
      onSubmit,
      required,
      resetStatus,
      showPass,
      statusAlert,
      valid,
    }
  },
})
