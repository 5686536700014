















import LoginForm from "@/components/LoginForm.vue"
import { useUser } from "@/store/user"

export default defineComponent({
  components: {
    LoginForm,
  },
  name: "Login",
  setup(_, { root }) {
    const user = useUser()

    watchEffect(() => {
      if (user.isConnected) root.$router.replace("/pipelines")
    })
  },
})
