














import { mdiClose } from "@mdi/js"

import { required } from "@/composables/formRules"
import { useApiStore } from "@/store/api"

export default defineComponent({
  setup() {
    const apiStore = useApiStore()
    const defaultUrl = process.env.VUE_APP_API_URL

    function updateApiUrl(val: string) {
      if (val) {
        apiStore.url = val
      }
    }

    return {
      apiStore,
      defaultUrl,
      mdiClose,
      required,
      updateApiUrl,
    }
  },
})
